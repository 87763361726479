import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ThemeService} from './shared/services/theme.service';
import {CartService} from './cart/services/cart.service';
import {UserService} from './shared/services/user/user.service';
import {AuthService} from './auth/services/auth.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public loading: any;
    private readonly languagesI18n: string [] = ['bg', 'cs', 'de', 'el', 'en', 'es', 'fr', 'hu', 'it', 'hl', 'pl', 'pt', 'sk']

    constructor(
        private translate: TranslateService,
        private themeService: ThemeService,
        public cartService: CartService,
        public userService: UserService,
        private authService: AuthService,
    ) {
        this.setDefaultLanguage();
    }

    private setDefaultLanguage(): void {
        const language: string = this.getParsedLanguageFromBrowser();
        this.translate.setDefaultLang(language);
        this.translate.use(language);
        document.documentElement.lang = language;
    }

    private getParsedLanguageFromBrowser(): string {
        const languageCode: string = navigator.language;
        const parsedLanguage = languageCode.split(/[_-]/);
        if (parsedLanguage && this.languagesI18n.includes(parsedLanguage[0])) {
            return parsedLanguage[0];
        }
        return 'en';
    }


    public logout(): void {
        if (this.themeService.auth.logoutUrl && this.themeService.auth.logoutUrl !== '' && this.themeService.auth.theme === this.themeService.currentTheme) {
            this.userService.showRoleError = false;
            document.location.href = this.themeService.auth.logoutUrl;
        } else {
            this.authService.logout();
        }
    }
}
